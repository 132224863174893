import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { collection, getDocs, updateDoc, doc, getDoc } from 'firebase/firestore';
import { db } from './firebaseConfig';
import { SiweMessage } from 'siwe';
import { checksumAddress, createPublicClient, encodeFunctionData, decodeFunctionResult, http, getContract, parseUnits } from 'viem';
import { baseSepolia } from 'viem/chains';
import {
  createSmartAccountClient,
  ENTRYPOINT_ADDRESS_V06,
} from 'permissionless';
import { privateKeyToSimpleSmartAccount } from 'permissionless/accounts';
import { createPimlicoPaymasterClient } from 'permissionless/clients/pimlico';


// Replace with a working RPC URL
const rpcUrl = 'https://api.developer.coinbase.com/rpc/v1/base-sepolia/MjedAxltHoKi2ys6lIIF0SArbTTM-TlM';
const usdcContractAddress = '0x036CbD53842c5426634e7929541eC2318f3dCF7e';

const FanPage = ({ provider }) => {
  const [artists, setArtists] = useState([]);
  const [selectedTier, setSelectedTier] = useState('');
  const tiers = ['Bronze', 'Silver', 'Gold', 'Platinum', 'Diamond'];
  const [userAddress, setUserAddress] = useState('');

  useEffect(() => {
    const getUserAddress = async () => {
      try {
        const addresses = await provider.request({ method: 'eth_requestAccounts' });
        setUserAddress(checksumAddress(addresses[0])); // Checksum the address
      } catch (error) {
        console.error('Error getting user address:', error);
        toast.error('Please connect your wallet');
      }
    };

    getUserAddress();
  }, [provider]);

  useEffect(() => {
    // Fetch artists from Firestore
    const fetchArtists = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'artists'));
        const artistsList = querySnapshot.docs.map(doc => ({
          docId: doc.id, // Document ID in Firestore
          ...doc.data()
        }));
        setArtists(artistsList);
      } catch (error) {
        console.error('Error fetching artists:', error);
        toast.error('Failed to fetch artists');
      }
    };

    fetchArtists();
  }, []);

  const signInWithEthereum = async (provider) => {
    try {
      const message = new SiweMessage({
        domain: window.location.host,
        address: userAddress, // Normalize the case of the address
        statement: 'Sign in with Ethereum to the application.',
        uri: window.location.origin,
        version: '1',
        chainId: 1,
      });

      const messageToSign = message.prepareMessage();
      const signature = await provider.request({
        method: 'personal_sign',
        params: [messageToSign, userAddress],
      });

      const recoveredAddress = await message.recoverAddress(signature);
      if (recoveredAddress.toLowerCase() !== userAddress.toLowerCase()) {
        throw new Error('Address mismatch');
      }

      return true;
    } catch (error) {
      console.error('Error during SIWE sign-in:', error);
      toast.error('Sign-In with Ethereum failed.');
      return false;
    }
  };

  const subscribe = async (artistDocId, tierName) => {
    try {
      if (!provider) {
        toast.error('Ethereum provider not available. Please connect your wallet.');
        return;
      }

      const accounts = await provider.request({ method: 'eth_requestAccounts' });
      const userAddress = accounts[0];

      if (!userAddress) {
        toast.error('Please sign in to subscribe');
        return;
      }

      const artistDocRef = doc(db, 'artists', artistDocId);
      const artistDoc = await getDoc(artistDocRef);

      if (!artistDoc.exists()) {
        toast.error('Selected artist does not exist');
        return;
      }

      const artistData = artistDoc.data();
      const selectedTier = artistData.tiers.find(t => t.name === tierName);

      if (!selectedTier) {
        toast.error('Selected tier does not exist');
        return;
      }

      const artistAddress = artistData.address;
      const artistName = artistData.name;

      const confirmation = window.confirm(`Pay ${selectedTier.price} USD to subscribe to ${artistName} ${tierName} tier?`);

      if (!confirmation) {
        toast.info('Payment cancelled');
        return;
      }

      const isAuthenticated = await signInWithEthereum(provider);

      if (!isAuthenticated) {
        toast.error('Sign In With Ethereum Failed');
        return;
      }


      const publicClient = createPublicClient({
        transport: http(rpcUrl),
      });

      const simpleAccount = await privateKeyToSimpleSmartAccount(publicClient, {
        privateKey: '0x38fcd15834fd4db1c299d708d22e208f9c81c0c5a47920e7fa9d6b5954d3f750',
        factoryAddress: '0x9406Cc6185a346906296840746125a0E44976454',
        entryPoint: ENTRYPOINT_ADDRESS_V06,
      });

      const pimlicoPaymaster = createPimlicoPaymasterClient({
        chain: baseSepolia,
        transport: http(rpcUrl),
        entryPoint: ENTRYPOINT_ADDRESS_V06,
      });

      const smartAccountClient = createSmartAccountClient({
        account: simpleAccount,
        chain: baseSepolia,
        bundlerTransport: http(rpcUrl),
        middleware: {
          sponsorUserOperation: pimlicoPaymaster.sponsorUserOperation,
        },
      });

      const minTokenAbi = [
        {
          inputs: [
            { internalType: 'address', name: 'to', type: 'address' },
            { internalType: 'uint256', name: 'value', type: 'uint256' },
          ],
          name: 'transfer',
          outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
          name: 'balanceOf',
          outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'decimals',
          outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
          stateMutability: 'view',
          type: 'function',
        },
      ];

      // Step 2: Check the number of decimals for the USDC token
      const usdcContract = getContract({
        address: usdcContractAddress,
        abi: minTokenAbi,
        publicClient,
      });
      const decimals = await publicClient.readContract({
        address: usdcContractAddress,
        abi: minTokenAbi,
        functionName: 'decimals',
      });

      // Step 3: Check the balance of the sender's address
      const balance = await publicClient.readContract({
        address: usdcContractAddress,
        abi: minTokenAbi,
        functionName: 'balanceOf',
        args: [userAddress],
      });

      
      const amount = parseUnits(selectedTier.price.toString(), decimals);
       // eslint-disable-next-line no-undef
      if (BigInt(balance) < amount) {
        toast.error('Insufficient USDC balance');
        return;
      }

      // Step 4: Create the transaction data for Circle USDC payment
      const callData = encodeFunctionData({
        abi: minTokenAbi,
        functionName: 'transfer',
        args: [artistAddress, amount.toString()],
      });

      // Step 5: Send the sponsored transaction
      try {
        const txHash = await smartAccountClient.sendTransaction({
          account: smartAccountClient.account,
          to: usdcContractAddress,
          data: callData,
          value: 0n,
        });

        console.log("✅ Transaction successfully sponsored!");
        toast.success(`✅ Transaction successfully. 🔍 View on Etherscan: https://sepolia.basescan.org/tx/${txHash}`);

        // Step 6: Update Firestore with the new subscription
        const updatedSubscribers = [
          ...(artistData.subscribers || []),
          { address: userAddress, tier: tierName }
        ];

        await updateDoc(artistDocRef, { subscribers: updatedSubscribers });

        toast.success('Subscription successful!');
      } catch (sponsorError) {
        console.error('Error during sponsored transaction:', sponsorError);
        toast.error('Sponsored transaction failed. Trying fallback method.');

        // Fallback: Directly pay using user's gas
        try {
          const callDataFallback = encodeFunctionData({
            abi: minTokenAbi,
            functionName: 'transfer',
            args: [artistAddress, amount.toString()],
          });
          const txFallback = await provider.request({
            method: 'eth_sendTransaction',
            params: [
              {
                from: userAddress,
                to: usdcContractAddress,
                data: callDataFallback,
                value: '0x0', // No ETH is being sent, only token transfer
              },
            ],
          });

          console.log("✅ Transaction successfully paid using user's gas!");
          console.log(`🔍 View on Etherscan: https://sepolia.basescan.org/tx/${txFallback}`);

          const updatedSubscribers = [
            ...(artistData.subscribers || []),
            { address: userAddress, tier: tierName }
          ];

          await updateDoc(artistDocRef, { subscribers: updatedSubscribers });

          toast.success('Subscription successful!');
        } catch (fallbackError) {
          console.error('Error during fallback transaction:', fallbackError);
          toast.error('Subscription failed.');
        }
      }
    } catch (error) {
      console.error('Error subscribing:', error);
      toast.error('Subscription failed');
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <ToastContainer />
      <h1 className="text-3xl font-bold mb-6">Content Creators</h1>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {artists.map((artist) => (
          <div key={artist.docId} className="bg-gray-800 p-4 rounded-lg">
            <div className="flex items-center mb-4">
              <h2 className="text-xl font-bold">{artist.name}</h2>
              <span className="ml-2 text-yellow-500">&#10004;</span> {/* Gold checkmark */}
            </div>
            <img src={artist.avatar} alt={artist.name} className="w-32 h-32 rounded-full mb-4" />
            <p className="mb-4">{artist.genre}</p>
            {artist.tiers && artist.tiers.map((tier) => (
              <div key={tier.name} className="mb-4">
                <h3 className="text-lg font-bold">{tier.name}</h3>
                <p>{tier.price} USD</p>
                <p>{tier.benefits}</p>
              </div>
            ))}
            <select
              className="block w-full mb-4 px-4 py-2 bg-gray-700 rounded-lg"
              onChange={(e) => setSelectedTier(e.target.value)}
            >
              <option value="">Select Tier</option>
              {tiers.map((tier) => (
                <option key={tier} value={tier}>{tier}</option>
              ))}
            </select>
            <button
              className="mt-4 px-4 py-2 bg-indigo-600 rounded-lg text-white"
              onClick={() => subscribe(artist.docId, selectedTier)}
            >
              Subscribe
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FanPage;
